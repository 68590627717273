<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Receipts</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-select
              v-model="selectedStatus"
              :items="statuses"
              label="Filter by Status"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="userId"
              label="Search User by UserId"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-btn color="primary" @click="getReceipts">Filter</v-btn>
          </v-col>
        </v-row>

        <v-expansion-panels popout>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Status</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <data-chart :chart-options="chartOptions" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-data-table
          :headers="headers"
          :items="formattedReceipts"
          class="elevation-1 mt-4"
        >
          <template v-slot:item.receiptImage="{ item }">
            <v-img
              :src="item.receiptImage"
              max-width="100"
              max-height="100"
              @click="openDialog(item.receiptImage)"
              class="clickable"
            ></v-img>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-row no-gutters>
              <v-col>
                <v-btn
                  class="mr-2"
                  small
                  :disabled="item.status == 'approved'"
                  color="green"
                  @click="updateReceiptStatus(item, 'approved')"
                  >Approve</v-btn
                >
              </v-col>
              <v-col>
                <v-btn
                  small
                  :disabled="item.status == 'rejected'"
                  color="red"
                  @click="updateReceiptStatus(item, 'rejected')"
                  >Reject</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.dataEntry="{ item }">
            <v-row no-gutters>
              <v-col>
                <v-btn
                  :disabled="
                    item.hasData === true ||
                    item.status === 'rejected' ||
                    item.status === 'pending'
                  "
                  class="mr-2 white--text custom-button"
                  small
                  color="pink"
                  @click="goToDataPage(item)"
                  >Add Data</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Receipt Image</v-card-title>
        <v-card-text class="text-center">
          <v-img :src="selectedImage" max-width="100%"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2";
import moment from "moment";
import DataChart from "./components/Chart.vue";
const service = new RestResource();

export default {
  components: {
    DataChart,
  },
  data() {
    return {
      receipts: [],
      dialog: false,
      selectedImage: "",
      statuses: ["pending", "approved", "rejected"],
      selectedStatus: "pending",
      userId: null,
      chartOptions: [],
      headers: [
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
        { text: "Receipt Image", value: "receiptImage" },
        { text: "Points Awarded", value: "pointsAwarded" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "Data Entry", value: "dataEntry", sortable: false },
      ],
    };
  },

  computed: {
    formattedReceipts() {
      return this.receipts.map((receipt) => ({
        ...receipt,
        createdAt: moment(receipt.createdAt).format("DD-MMM-YYYY"),
      }));
    },
  },

  mounted() {
    this.getReceipts();
    this.getTotalCount();
  },

  methods: {
    getReceipts() {
      let dataToSend = {
        status: this.selectedStatus,
        userId: this.userId !== null ? this.userId : null,
      };
      this.$setLoader();
      service.getReceiptData(dataToSend).then((r) => {
        this.receipts = r.data;
        this.$disableLoader();
      });
    },

    getTotalCount() {
      service.getTotalReceiptCount().then((res) => {
        console.log(res.data.count);
        this.chartOptions = this.generateChartData(res.data.count);
      });
    },

    updateReceiptStatus(item, status) {
      let dataToSend = {
        status,
        submissionId: item._id,
        userId: item.userId,
      };
      this.$setLoader();
      service.updateReceiptStatus(dataToSend).then(() => {
        this.getReceipts();
      });
      this.$disableLoader();
    },

    goToDataPage(item) {
      const url = this.$router.resolve({
        name: "MobileV2ReceiptAddData",
        params: { userId: item.userId, receiptId: item._id },
      }).href;

      window.open(url, "_blank");
    },
    openDialog(imageUrl) {
      this.selectedImage = imageUrl;
      this.dialog = true;
    },
    generateChartData(data) {
      
      const statusApprovedCount =
        data[0].statusApprovedCount > 0 ? data[0].statusApprovedCount : 1;
      const hasDataTrueCount =
        data[0].hasDataTrueCount > 0 ? data[0].hasDataTrueCount : 1;

      
      const difference = statusApprovedCount - hasDataTrueCount;

      return {
        chart: {
          type: "column",
        },
        title: {
          text: "Number of Records",
        },
        xAxis: {
          categories: ["Status Approved", "Data Submitted"],
        },
        yAxis: {
          min: 1,
          type: "logarithmic",
          title: {
            text: "Count",
          },
        },
        series: [
          {
            name: "Records",
            data: [
              {
                y: statusApprovedCount,
                color: "#28a745",
              },
              {
                y: hasDataTrueCount,
                color: "#dc3545", 
              },
            ],
          },
          {
            type: "line",
            name: "Difference",
            data: [
              {
                y: statusApprovedCount, 
                marker: {
                  enabled: false,
                },
              },
              {
                y: hasDataTrueCount,
                marker: {
                  enabled: false,
                },
              },
            ],
            color: "#007bff",
            enableMouseTracking: false, 
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true,
          formatter: function () {
            return `<strong>${this.x}</strong>: <b>${this.points[0].y}</b>`;
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
          line: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return difference >= 0 ? `Pending ${difference}` : difference;
              },
              color: "#000000",
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.v-img.clickable {
  cursor: pointer;
}

.custom-button {
  color: white;
}
</style>
