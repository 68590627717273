<template>
    <div>
        <dataChart :options="chartOptions" :constructor-type="'chart'"></dataChart>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
    components: {
        dataChart: Chart
    },
    props: {
        chartOptions: Object
    }
}
</script>